import { CreateDocumentRequestType, FinanceUploadFileType } from '../../../../types';
import { createDocument, uploadDocument } from '../../redux/calculate';

export const useDocuments = (partnerId: string) => {
  const createAndUploadDocument = async (file: FinanceUploadFileType, projectId?: string, leadId?: string, customConfig?: CreateDocumentRequestType) => {
    const defaultDocParams = projectId ? {
      projectId: projectId,
      sectionIds: [file._id],
    } : undefined;
    const leadConfig = {
      leadId: leadId,
      sectionIds: [file._id],
    };
    const documentId = await createDocument(customConfig || defaultDocParams || leadConfig, partnerId);
    if (documentId) {
      const uploadedFile = await uploadDocument({
        ...file,
        _id: documentId?.[0] || '',
      }, partnerId);
      return uploadedFile;
    }
  };

  return { createAndUploadDocument };
};
