export const roleList = ['user', 'analyst', 'admin', 'external-user'] as const;

export type Role = typeof roleList[number];

export const roleTitles = {
  'user': 'Customer',
  'analyst': 'Analyst',
  'admin': 'Administrator',
};

export interface SharedProfile {
  name?: string;
  surname?: string;
  phoneNumber?: string;
  agreedSubscription?: boolean;
  agreedTerms?: boolean;
  roleCalculator?: Role;
  deleteToken?: string;
  roleProverest2?: Role;
}

export interface InitResponse {
  user: User;
  notVerifiedEmail?: string;
}

export interface ShortUser {
  id: string;
  displayName: string;
  role: Role;
  phoneNumber?: string;
  email?: string;
  lastSeenHours: number;
}

export type Profile = SharedProfile;

export interface User extends Profile {
  id: string;
  role: Role;
  email: string;
  tariff?: string;
  setTariffDate?: Date | string;
  expireTariffDate?: Date | string;
  confirmed?: boolean;
  partnerId: string;
}

export type UserMetadata = SharedProfile;

export interface PartnerUserRow {
  _id: string;
  userId: string;
  partnerId: string;
  email: string;
  createdById: string;
  subdomain: string;
  companyName: string;
}
